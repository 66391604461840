import EmploymentStatus from '../EmploymentStatus';

import { Stack } from '@oms/ui-stack';

export const Profession = () => {
  return (
    <Stack gap={4}>
      <EmploymentStatus />
    </Stack>
  );
};

export default Profession;
