import { Box } from '@oms/ui-box';
import { Button } from '@oms/ui-button';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Stack } from '@oms/ui-stack';
import { FieldArray } from 'react-final-form-arrays';
import { required } from '../validate';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';
import styled from '@emotion/styled';

// const initialPositions: any[] = [{}];

type Props = { name: string };

const Space = styled.div`
  padding: 12px 4px;
`;

const PoaArray = ({ name }: Props) => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.map((poaFieldName: string, index: number) => (
            <Stack
              my={4}
              as="fieldset"
              key={poaFieldName}
              gap={2}
              border="sm"
              p={4}
            >
              <Box>
                <label>Fullmektigens nr. {index + 1}</label>
                {index !== 0 && (
                  <Button ml={4} size="xs" onClick={() => fields.remove(index)}>
                    Fjern person
                  </Button>
                )}
              </Box>

              <PdfTextInput
                name={`${poaFieldName}.powerOfAttorney.name`}
                label="Fullmektigens navn"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${poaFieldName}.powerOfAttorney.address`}
                label="Adresse"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${poaFieldName}.powerOfAttorney.personalNumber`}
                label="Personnummer"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${poaFieldName}.powerOfAttorney.epost`}
                label="Epost"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${poaFieldName}.powerOfAttorney.mobilNumber`}
                label="Mobilnummer"
                isPDFRender={isPDFRender}
              />
              <Space />
              <Box>
                <Fieldset
                  as={RadioGroup}
                  validate={required}
                  name={`${poaFieldName}.powerOfAttorney.organizationProxies`}
                  legend=" Er fullmektige innenfor samme organisasjon?"
                >
                  <Field
                    as={Radio}
                    validate={required}
                    label="Ja"
                    value="true"
                  />
                  <Field
                    as={Radio}
                    validate={required}
                    label="Nei"
                    value="false"
                  />
                </Fieldset>
              </Box>
              <Space />
              <Box>
                <Fieldset
                  as={RadioGroup}
                  validate={required}
                  name={`${poaFieldName}.powerOfAttorney.authorizedIncome`}
                  legend="Mottar fullmaktshaver inntekter?"
                >
                  <Field
                    as={Radio}
                    validate={required}
                    label="Fullmaktshaver mottar inntekter og har konsesjon til å drive aktiv forvaltning på vegne av kunden"
                    value="true"
                  />
                  <Field
                    as={Radio}
                    validate={required}
                    label="Fullmaktshaver mottar ikke inntekter for å handle på vegne av kunden"
                    value="false"
                  />
                </Fieldset>
              </Box>
              <Space />
            </Stack>
          ))}
          <Button
            type="button"
            variant="tertiary"
            onClick={() => fields.push({})}
          >
            Legg til person
          </Button>
        </>
      )}
    </FieldArray>
  );
};

export default PoaArray;
