import React from 'react';
import { Loading, NoData } from '@oms/components-core';
import { useCompanyInfo } from '@oms/components-company-info';
import { Card, CardProps } from 'components/Card';
import { Table, Td, Th } from '@oms/ui-table';
import { mapGics, mapSI } from '@oms/utils';
import { useItemSector } from 'context/ItemSectorContext';

export interface CompanyInfoProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const CompanyInfo = ({ itemSector, ...props }: CompanyInfoProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card title="Selskapsinfo" {...props}>
      <CompanyInfoComponent itemSector={itemSectorValue} />
    </Card>
  );
};

function getSectorName(data: ReturnType<typeof useCompanyInfo>['data']) {
  if (data?.GICS_CODE) mapGics(data?.GICS_CODE);
  if (data?.SECTOR_INDEX_MEMBER_OF) return mapSI(data?.SECTOR_INDEX_MEMBER_OF);
  return '-';
}

export const CompanyInfoComponent = ({
  itemSector,
}: {
  itemSector: string;
}) => {
  const { data, initialized, resource, emptyData } = useCompanyInfo({
    itemSector,
  });

  if (!initialized) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    //@ts-ignore
    return <NoData text="No return found" />;
  }

  return (
    <Table variant="keyValue">
      <tbody>
        <tr>
          <Th scope="row">ISIN</Th>
          <Td>{data.ISIN}</Td>
        </tr>
        <tr>
          <Th scope="row">Foretaksnummer</Th>
          <Td>{data.ORGANISATION_NUMBER || '-'}</Td>
        </tr>
        <tr>
          <Th scope="row">Sektor</Th>
          <Td>{getSectorName(data)}</Td>
        </tr>
        <tr>
          <Th scope="row">Antall aksjer</Th>
          <Td>{data.ISSUED_NUMBER_OF_SHARES}</Td>
        </tr>
        <tr>
          <Th scope="row">Nettsted</Th>
          <Td
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <a href={data.COMPANY_URL} title={data.COMPANY_URL}>
              {data.COMPANY_URL || '-'}
            </a>
          </Td>
        </tr>
      </tbody>
    </Table>
  );
};
